// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$tpt-primary: mat.define-palette(mat.$blue-palette);
$tpt-accent: mat.define-palette(mat.$brown-palette, A200, A100, A400);

$tpt-warn: mat.define-palette(mat.$red-palette);
$tpt-success: mat.define-palette(mat.$green-palette);
$tpt-danger: mat.define-palette(mat.$orange-palette);
// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$tpt-theme: mat.define-light-theme((
  color: (
    primary: $tpt-primary,
    accent: $tpt-accent,
    warn: $tpt-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($tpt-theme);
@include mat.all-component-themes($tpt-theme);

.mat-success {
  background-color: mat.get-color-from-palette($tpt-success, 200)!important;
  color: mat.get-color-from-palette($tpt-success, 200-contrast)!important;
}

.mat-danger {
  background-color: mat.get-color-from-palette($tpt-danger, 500);
  color: mat.get-color-from-palette($tpt-danger, 500-contrast);
}


// Tailwind Directives
@import "~tailwindcss/base.css";
@import "~tailwindcss/components.css";
@import "~tailwindcss/utilities.css";

@import '~quill/dist/quill.snow.css';

// Base Styles
html, body { height: 100%; }
body { margin: 0; font-family: "Inter", "Helvetica Neue", sans-serif; }
h1 { font-size: 24.3pt; }
h2 { font-size: 21.6pt; }
h3 { font-size: 19.2pt; }
h4 { font-size: 17.1pt; }
h5 { font-size: 15.3pt; }
h6 { font-size: 13.7pt; }
p { font-size: 12pt; }
small { font-size: 10pt; }

.content-panel {
  padding : 2em;
  background: white;
}
.content-panel-header {
  width: 100%;
  padding-bottom: 1em;
}

// Custom Styles
.mat-select-panel, .mat-mdc-text-field-wrapper {
  background: white!important;
}
button {
  @apply bg-blue-500 text-white py-3 px-5 font-bold rounded;
  cursor: pointer;
  user-select: none;
  // border: 1px solid #ddd!important;
}
button:disabled {
  @apply bg-gray-200 text-white font-bold rounded;
}
.btn {
  padding-top: 16px;
  padding-bottom: 16px;
}
.btn-secondary {
  @apply bg-neutral-400 text-white font-bold rounded;
}
.btn-primary-outline {
  @apply border border-neutral-400 bg-blue-500 text-white font-bold rounded;
}
.btn-secondary-outline {
  @apply border border-neutral-400 bg-transparent text-neutral-600 font-bold rounded;
}
.btn-warn-outline {
  @apply border border-red-800 text-red-800 font-bold rounded;
}

// Radio button styles
input[type="radio"] {
  margin-right: 1.25rem;
}

.form-button {
  padding-left: 1.5rem!important;
  padding-right: 1.5rem!important;
  padding-top: 0.875rem!important;
  padding-bottom: 0.875rem!important;
  cursor: pointer;
}

.form-button:disabled {
  background-color: #f5f5f5!important;
  color: #ccc!important;
  cursor: default;
}

.outline {
  background-color: white!important;
  // color : #333;
  border: 1px solid #ddd!important;
}
.outline:hover {
  background-color: #f5f5f5!important;
}

.outline .warning {
  background-color: white!important;
  border-color: #ffcccc!important;
  color: #ffcccc!important;
}
.outline .warning:hover {
  background-color: #ffcccc!important;
}

.mat-mdc-dialog-title {
  padding-left: 0!important;
  font-weight: 800!important;
  font-size: 17.1pt!important;
}
.portlet-table-row:hover {
  background-color: #f5f5f5;
}
.center {
  margin-left: auto;
  margin-right: auto;
}

.drag-list {
  width: 100%;
  border: solid 1px #ccc;
  margin-top: auto;
  margin-bottom: auto;
  min-height: 60px;
  display: block;
  background: white;
  border-radius: 4px;
  overflow: hidden;
}

.drag-box {
  width: 100%;
  padding: 1em 1em;
  border-bottom: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: move;
  background: white;
  font-size: 14px;
}

.cdk-drag-preview {
  border: none;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-box:last-child {
  border: none;
}

.drag-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-hover {
  background-color: #cdcdcd!important;
  transition: all 0.3s;
}

.bg-invalid, .bg-incomplete {
  background-color: #ffcccc!important;
  transition: all 0.3s;
}

mat-label {
  cursor: default;
}

// Add required asterisk to mat-label
mat-label.required::after {
  content: "*";
  color: red;
}


// Add padding to material tooltips
.mdc-tooltip__surface {
  padding: 1em 2em!important;
  max-width: 25vw!important;
}

.mdc-tab__content {
  font-weight: 600;
}

.mat-button-toggle-checked {
  background-color: mat.get-color-from-palette($tpt-primary)!important;
  color: white!important;
}

mat-paginator button {
  border-radius: 50%!important;
  margin-left: 0.25em;
  margin-right: 0.25em;
  border : 1px solid #ddd!important;
}

.searchInput {
  width: 25em;
  min-width: 25em;
}
.cursor-default {
  cursor: default;
}

mat-datepicker-toggle button {
  border: 0!important;
}

.mat-calendar-table button {
  border : 0!important;
  padding-left: 0;
  padding-right: 0;
}

mat-slide-toggle {
  margin-top: 1em;
  transform : scale(1.6) translateX(.5em);
}
mat-slide-toggle button {
  border-radius: 7px!important;
}

.pro-tag {
  background-color: #0000ff;
  color: #fff;
  font-weight: 600;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  cursor: default;
}

.warn-tag {
  // light orange
  background-color : #eebb00;
  color: #fff;
  font-weight: 400;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  cursor: default;
}

.portlet-table-row:hover {
  background-color: #f1f1f1;
}

mat-menu button {
  border: 0!important;
}

mat-dialog-actions {
  padding: 20px 24px!important;
}

.mat-expansion-indicator, .mat-expansion-indicator::after {
  border-color: #333!important;
  margin-left: 1em;
  margin-right: 1em;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  white-space: pre-line;
}

quill-editor {
  height: 15em;
}

a.buttonlink {  
  background: #999;
  color: #fff;
  border-radius: 5px;
  padding: .25em .5em;
  margin-left: .25em;
  margin-right: .25em;
}